import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Link } from "gatsby";
import { ByLine } from "../../layout";
import { BlogEntry, DesktopRightImage, Image } from "../../components";
export function CoverImage() {
  // const images = [
  //   "trailguide_0",
  //   "trailguide_2",
  //   "trailguide_3",
  //   "trailguide_4",
  //   "trailguide_26",
  //   "trailguide_36",
  //   "trailguide_46",
  //   "trailguide_48",
  //   "trailguide_49",
  //   "trailguide_53",
  //   "trailguide_54",
  //   "hogevarde",
  // ]
  // const img = images[Math.floor(Math.random() * images.length)]
  // return <Image src={`/frontpage/${img}`} />
  return <Image src="/frontpage/trailguide_3.jpg" mdxType="Image" />;
}
export const _frontmatter = {};
const layoutProps = {
  CoverImage,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <h1 className="px-2 text-center">
  Help us build a leading business in the travel tech sector
    </h1>
    <br />
    <CoverImage mdxType="CoverImage" />
    <br />
    <h4>{`Technology for the activity tourism`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  Since 2017 Trailguide has developed technology to digitalize outdoor
  activities and help the tourism sector offer better outdoor experiences for
  their guests and visitors.
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  By using our solutions guests get immediate access to digital tour
  information, maps and GPS navigation through a QR code.
    </div>
    <br />
    <h4>{`Help us build a company`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  The current team loves to develop great stuff, and that is what we did. We did
  not build a company because we do not have the abilities to do so. We built a
  great software platform and technology for the activity tourism and now we
  need help to build a company around it.
    </div>
    <br />
    <h4>{`What do we need?`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  There are several possible ways forward, some of them might be:
      <ol>
        <li parentName="ol">
          <p parentName="li">{`Access to capital and people who can help us put together the right business team
around our technology and build a multimillion dollar software company.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Sell the company to a suitable company that can integrate our technology into their
own products. The current team will come along as hired developers.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`Sell parts of our technology to a suitable company that can integrate it
into their own products. The current team will help in the integration phase.`}</p>
        </li>
      </ol>
    </div>
    <br />
    <h4>{`Are you the right person?`}</h4>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  If you feel you are any of the following, we would like to talk to you:
      <br />
      <ol>
        <li parentName="ol">
          <p parentName="li">{`You are the CEO of a travel tech company and are looking for technology to extend
your offerings and accellerate your growth.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`You have access to funding and the people who can help put together the right
business team and want to invest.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`You have the funds and the skills and want to purchase the tech and build a
multimillion software company.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`You think you have the ability to find a suitable company that can purchase
Trailguide AS and all of its assets and integrate it into their own products.`}</p>
        </li>
        <li parentName="ol">
          <p parentName="li">{`You think you have the ability to find a suitable company that can purchase
parts of our technology and integrate it into their own products.`}</p>
        </li>
      </ol>
    </div>
    <div className="sm:text-lg leading-7 sm:leading-9 my-3">
  In my opinion, the right person does not need to have a deep knowledge of
  technology. The right person needs to understand the problem domain,
  understand who the customer is (or can be), and have a deep desire and hunger
  (and ability) to build a multimillion dollar tech company.
    </div>
    <br />
    <Link to="problems" mdxType="Link">Read more about the problems we are solving</Link>
    <br />
    <Link to="customers" mdxType="Link">Read more about the customers we are targeting</Link>
    <br />
    <Link to="solutions" mdxType="Link">Read more about the solutions we provide</Link>
    <br />
    <Link to="potential" mdxType="Link">Read more about the market potential</Link>
    <br />
    <Link to="tech" mdxType="Link">Read more about the technology</Link>
    <br />
    <Link to="assets" mdxType="Link">Read more about the IP and assets</Link>
    <br />
    <Link to="team" mdxType="Link">Read more about the team</Link>
    <br />
    <br />
    <br />
    <p>{`We have a more detailed investor prospect available if you want more information.`}</p>
    <br />
If this is for you, leave me an email or SMS and I will get back to you shortly.
    <br />
    <br />
    <br />
    <ByLine author="Bjørn Jarle Kvande" mdxType="ByLine" />
    <div className="text-sm mt-3 text-gray-700 leading-6">
  Founder and managing director
  <br />
  bjorn.jarle.kvande
  <span className="hidden">If you see this, apply for a job</span>@<span className="hidden">
    as a programmer at Trailguide
  </span>
  trailguide.no
  <br />
  +47 916 36 159
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      